import styled from '@mui/material/styles/styled';
import { NavLink } from 'react-router-dom';

const NavLinkStyled = styled(NavLink)();
const Component = ({ sx, ...rest }: any) => (
    <NavLinkStyled sx={{ ...navLinkSx, ...sx }} {...rest} />
);

export default Component;

const navLinkSx = {
    pl: 1.5,
    pr: 1.5,
    textDecoration: 'none',
    fontSize: '15px',
    fontFamily: 'Roboto',
    color: 'gray',
    transition: 'color 0.2s ease-in-out',
    '&:hover': {
        color: 'white'
    },
}
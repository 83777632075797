
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia, { CardMediaProps } from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

interface CardComponentProps {
    image: CardMediaProps['image'];
    imageAlt: string;
    title: string;
    descr: string;
}

const CardComponent = (props: CardComponentProps) => (
    <Card sx={{ width: '300px' }}>
        <CardMedia
            component="img"
            height="194"
            image={props.image}
            alt={props.imageAlt}
            sx={cardImgSx}
        />
        <CardContent sx={{ backgroundColor: 'black' }}>
            <Typography gutterBottom variant="h5" component="div">
                {props.title}
            </Typography>
            <Typography variant="body2">
                {props.descr}
            </Typography>
        </CardContent>
    </Card>
);

export default CardComponent;


const cardImgSx = {
    p: 1,
    backgroundColor: 'black',
    boxSizing: 'border-box'
};
import React from 'react';
import ReactGA from 'react-ga';
import { isEmpty } from 'lodash';
import Dropzone from './Dropzone';
import ReCAPTCHA from './ReCAPTCHA';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import { useMediaQuery } from 'react-responsive';
import Typography from '@mui/material/Typography';

const DEFAULT_VALUES = {
  name: undefined,
  email: undefined,
  message: undefined,
  "g-recaptcha-response": undefined,
};

export default function ContactForm({
  attachmentEnabled = false
}: FormProps) {
  const isTinyWidth = useMediaQuery({ query: '(max-width: 435px)' });
  const [files, setFiles] = React.useState<File[]>([]);
  const [formError, setFormError] = React.useState('');
  const [formSuccess, setFormSuccess] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [values, setValues] = React.useState<FormValues>({ ...DEFAULT_VALUES });
  const [errors, setErrors] = React.useState<FormValues>({ ...DEFAULT_VALUES });

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = typeof event === 'string' ? event : event.target.value;
    setValues({ ...values, [prop]: value });
    setErrors({ ...errors, [prop]: isEmpty(value) });
  };

  const handleChangeToken = (prop: string) => (token: string | null) => {
    setValues({ ...values, [prop]: token });
    setErrors({ ...errors, [prop]: isEmpty(token) });
  };

  const handleBlur = (prop: string) => (event: React.FocusEvent<HTMLInputElement>) => {
    const value = typeof event === 'string' ? event : event.target.value;
    setValues({ ...values, [prop]: value });
    setErrors({ ...errors, [prop]: isEmpty(value) });
  }

  const stateIsInvalid = () => {
    let errorStatus = false;

    for (const key in values) {
      if (isEmpty(values[key])) {
        setErrors({ ...errors, [key]: true });
        errorStatus = true;
      }
    }

    if (errorStatus) {
      ReactGA.exception({
        description: 'Contact form not fully filled out when user tried to submit',
        fatal: false
      });
    }

    return errorStatus;
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (stateIsInvalid()) {
      return;
    }

    setFormSuccess('');
    setIsSubmitting(true);

    const formData = new FormData();

    for (const key in values) {
      formData.append(key, values[key]);
    }

    files.forEach(file =>
      formData.append('media', file));

    const url = 'https://' + window.location.host + '/api/contact';
    const options = {
      method: 'POST',
      body: formData,
    };

    ReactGA.event({
      category: 'Contact Form',
      action: 'Submitted',
      label: 'Contact Form Submitted'
    });

    fetch(url, options)
      .then(response => response.json())
      .then(async jsonPromise => {
        const json = await jsonPromise;

        setFiles([]);
        setFormSuccess(json.message + '! Thank you!');
        setValues({ ...DEFAULT_VALUES });
      })
      .catch(err => {
        console.error(err);

        ReactGA.exception({
          description: 'Contact form error after submission',
          fatal: false
        });

        setFormError('An unexpected error occurred. Please try again.');
      })
      .finally(() =>
        setIsSubmitting(false));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{
      ...rootSx,
      ...(isTinyWidth ? { maxWidth: '400px' } : { minWidth: '500px' })
    }} autoComplete="off">
      <Stack spacing={1}>
        <TextField
          name="name"
          label="Name"
          autoComplete="none"
          fullWidth
          required
          disabled={isSubmitting}
          value={values.name}
          error={Boolean(errors.name)}
          onBlur={handleBlur('name')}
          onChange={handleChange('name')}
          helperText={errors.name ? "A name is required." : " "}
          sx={textFieldSx}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={['fad', 'signature']} fixedWidth />
              </InputAdornment>
            ),
          }} />

        <TextField
          name="email"
          type="email"
          label="Email"
          autoComplete="none"
          fullWidth
          required
          disabled={isSubmitting}
          value={values.email}
          error={Boolean(errors.email)}
          onBlur={handleBlur('email')}
          onChange={handleChange('email')}
          helperText={errors.email ? "An email is required." : " "}
          sx={textFieldSx}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={['fad', 'at']} fixedWidth />
              </InputAdornment>
            ),
          }} />

        <TextField
          name="phone"
          label="Phone"
          autoComplete="none"
          fullWidth
          required
          disabled={isSubmitting}
          value={values.phone}
          error={Boolean(errors.phone)}
          onBlur={handleBlur('phone')}
          onChange={handleChange('phone')}
          helperText={errors.phone ? "A phone number is required." : " "}
          sx={textFieldSx}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={['fas', 'phone']} fixedWidth />
              </InputAdornment>
            ),
          }} />

        <TextField
          name="message"
          label="Message"
          autoComplete="none"
          fullWidth
          multiline
          rows={3}
          required
          disabled={isSubmitting}
          onBlur={handleBlur('message')}
          value={values.message}
          onChange={handleChange('message')}
          error={Boolean(errors.message)}
          helperText={errors.message ? "A message is required." : " "}
          sx={textFieldSx}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={['fad', 'envelope-open-text']} fixedWidth />
              </InputAdornment>
            ),
          }} />

        {attachmentEnabled && (
          <Dropzone
            files={files}
            setFiles={setFiles}
            disabled={isSubmitting}
          />
        )}

        <Box sx={recaptchaRowSx}>
          <ReCAPTCHA
            errors={errors}
            handleChange={handleChangeToken}
          />
        </Box>

        {formError && <Typography color="red">{formError}</Typography>}

        {formSuccess && <Typography>{formSuccess}</Typography>}

        <Box sx={buttonRowSx}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}>
            Submit &nbsp;
            {isSubmitting && <FontAwesomeIcon icon={['fad', 'spinner-third']} fixedWidth spin />}
            {!isSubmitting && <FontAwesomeIcon icon={['fad', 'handshake']} fixedWidth />}
          </Button>
        </Box>

      </Stack>
    </Box>
  );
}

interface FormProps {
  style?: {};
  title?: string;
  attachmentEnabled?: boolean;
}

interface FormValues {
  [key: string]: any;
  name: string | undefined;
  email: string | undefined;
  message: string | undefined;
  'g-recaptcha-response': string | undefined;
}

const rootSx = {
  p: 3,
  backgroundColor: 'rgb(17, 17, 17)',
  borderRadius: '8px',
  form: {
    display: 'flex',
    margin: '0.2rem',
    padding: '0.7rem',
    maxWidth: '33.5ch',
    flexDirection: 'column',
  }
}

const textFieldSx = {
  "& .MuiInputLabel-root": { color: 'white' },//styles the label
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: "gray" },
  },
  "& .MuiOutlinedInput-root:hover": {
    "& > fieldset": { borderColor: "white" },
  },
};

const buttonRowSx = {
  pt: 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
}

const recaptchaRowSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
}
import React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import StarField from '../components/StarField';
import CompassImg from '../artifacts/absolutvision-uCMKx2H1Y38-unsplash.jpg';
import CompSrc from '../artifacts/javier-allegue-barros-i5Kx0P8A0d4-unsplash.jpg';
import OutboundLink from '../components/OutboundLink';
import { useMediaQuery } from 'react-responsive';

const Contact = () => {
    const isTinyWidth = useMediaQuery({ query: '(max-width: 435px)' });
    React.useEffect(() => { document.title = 'Gripfire, Inc. - Careers' }, []);

    return (
        <Box sx={rootSx}>
            <Box sx={wrapperSx}>
                <StarField />
                <Box sx={leftColumnSx}>
                    <Typography variant="h2" sx={titleSx}>
                        Join the team
                    </Typography>

                    <Stack spacing={2}>
                        <Typography variant="h6" sx={descrSx}>
                            Gripfire, Inc. is always looking for talented professionals.
                        </Typography>

                        <Typography variant="h6" sx={descrSx}>
                            Gripfire, Inc. is an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to  race, color, religion, sex, national origin, age, disability or genetic information.
                        </Typography>
                    </Stack>
                </Box>
                {!isTinyWidth && (
                    <Box sx={rightColumnSx}>
                        <img src={CompSrc} width="300px" alt="teamwork" style={imgSx} />
                    </Box>
                )}
            </Box>
            <Box sx={openingsSx}>
                <OutboundLink
                    eventLabel="current-job-openings"
                    to="https://www.indeed.com/cmp/Gripfire,-Inc./jobs"
                    target="_blank"
                    sx={outboundLinkSx}
                    title="Current Job Openings">
                    Current opportunities with Gripfire, Inc!
                </OutboundLink>
            </Box>
        </Box>
    );
}

export default Contact;

const rootSx = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '6rem 0 4.5rem 0',
    position: 'relative',
    '&::before': {
        opacity: '.1',
        backgroundImage: 'url(' + CompassImg + ')',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
        content: '""',
    }
};

const openingsSx = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 6,
}

const wrapperSx = {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
};

const outboundLinkSx = {
    color: 'white',
    textDecoration: 'none',
    fontSize: 'large',
    '&:hover': { textDecoration: 'underline' }
};

const titleSx = { pb: 2, fontFamily: 'Roboto Condensed', textAlign: 'center' }

const descrSx = { fontFamily: 'Roboto Slab' };

const leftColumnSx = {
    p: 1,
    pt: 3,
    maxWidth: '460px'
}

const rightColumnSx = {
    minWidth: '548px'
}

const imgSx = { margin: '100px 0 0 80px', borderRadius: '5%' }
import React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import ContactForm from '../components/ContactForm';
import StarField from '../components/StarField';
import bgSrc from '../artifacts/robs-HOrhCnQsxnQ-unsplash.jpg';
import { useMediaQuery } from 'react-responsive';

const Contact = () => {
    const isTinyWidth = useMediaQuery({ query: '(max-width: 435px)' });
    React.useEffect(() => { document.title = 'Gripfire, Inc. - Contact' }, []);

    return (
        <Box sx={rootSx}>
            <StarField />
            <Box sx={wrapperSx}>
                {!isTinyWidth && (
                    <Box sx={leftColumnSx}>
                        <Typography variant="h2" sx={titleSx}>
                            Contact us
                        </Typography>

                        <Stack spacing={2}>
                            <Typography variant="h6" sx={descrSx}>
                                Gripfire, Inc. is a U.S. based company with locations in Maryland and Pennsylvania.
                                We provide a broad array of capabilites with an emphasis in specialized Software
                                Engineering.
                            </Typography>

                            <Typography variant="h6" sx={descrSx}>
                                Enabling utilities through applications that require a results-driven approach
                                is an achievable and measurable goal.
                            </Typography>
                        </Stack>
                    </Box>
                )}
                <Box>
                    <ContactForm />
                </Box>
            </Box>
        </Box>
    );
}

export default Contact;

const rootSx = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '6rem 0 4.5rem 0',
    position: 'relative',
    '&::before': {
        opacity: '.1',
        backgroundImage: 'url(' + bgSrc + ')',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
        content: '""',
    }
};

const wrapperSx = {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
};

const titleSx = { pb: 2, fontFamily: 'Roboto Condensed' }

const descrSx = { fontFamily: 'Roboto Slab' };

const leftColumnSx = {
    p: 1,
    pt: 3,
    maxWidth: '460px'
}
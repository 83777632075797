import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import backgroundVideo from '../artifacts/27239-362518579_small.mp4';
import Button from '../components/Button';
import Link from "../components/Link";
import { useMediaQuery } from 'react-responsive';

const Home = () => {
    const isTinyWidth = useMediaQuery({ query: '(max-width: 1824px)' });

    return (
        <Box sx={rootSx}>
            <Box component="video" autoPlay loop muted id="video" sx={videoSx}>
                <source src={backgroundVideo} type="video/mp4" />
            </Box>
            <Box sx={positionTopSx}>
                <Typography sx={{
                    ...statementSx,
                    ...(isTinyWidth ? {fontSize:'50pt'} : {fontSize: '65pt'})
                    }}>
                    Connecting capabilities with vision.
                </Typography>
                <Stack direction="row" justifyContent="center" spacing={2} sx={buttonRowSx}>
                    <Link to="/services">
                        <Button variant="outlined">Services</Button>
                    </Link>
                    <Link to="/contact">
                        <Button variant="contained">Contact us</Button>
                    </Link>
                </Stack>
            </Box>
        </Box>
    );
}

export default Home;

const rootSx = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

};

const videoSx = {
    top: '0',
    left: '0',
    bottom: '0',
    overflow: 'hidden',
    minWidth: '100%',
    minHeight: '100%',
    position: 'fixed',
    opacity: '.5'
}

const statementSx = {
    color: 'white',
    fontFamily: 'Roboto Condensed',
    textAlign: 'center',
    '-webkit-text-stroke': '.1px black',
}

const positionTopSx = {
    margin: 'auto',
    position: 'relative'
}

const buttonRowSx = {
    pt: '2rem'
}
import Button, { ButtonProps } from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#fff'
        }
    }
})

const ThemedButton = (props: ButtonProps) => (
    <ThemeProvider theme={theme}>
        <Button {...props} />
    </ThemeProvider>
)

export default ThemedButton


import { ReactComponent as LogoText } from './logo-text.svg';
import styled from '@mui/material/styles/styled';

const LogoTextStyled = styled(LogoText)();

const Text = () => (
  <LogoTextStyled width="6rem" sx={rootStyle} />
)

export default Text;

const rootStyle = {
  pointerEvents: 'none',
  height: '100%',
  marginTop: '.3rem'
}

import Logo from '../header/Logo';
import Box from '@mui/material/Box';
//import User from './User';
import Navigation from '../header/Navigation';

export default function Header() {
  return (
    <Box component="header" sx={rootSx}>
        <Logo />
        <Navigation />
        {/*<User />*/}
    </Box>
  );
}

const rootSx = {
  top: '0',
  zIndex: '1',
  width: '100%',
  position: 'fixed',
  height: '4.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  borderBottom: '1px solid dimgray'
}

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Fade } from "react-awesome-reveal";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import driveSrc from '../artifacts/benjamin-lehman-GNyjCePVRs8-unsplash.jpg';
import goalsImg from '../artifacts/afif-ramdhasuma-jl4BQJs87Do-unsplash.jpg';
import trendsImg from '../artifacts/alessandro-bianchi-_kdTyfnUFAc-unsplash.jpg';
import StarField from '../components/StarField';
import focusImgSrc from '../artifacts/fabio-oyXis2kALVg-unsplash.jpg';
import bgSrc from '../artifacts/jj-ying-8bghKxNU1j0-unsplash.jpg';
import dbImg from '../artifacts/tobias-fischer-PkbZahEG2Ng-unsplash.jpg';
import serviceImg from '../artifacts/growtika-ZfVyuV8l7WU-unsplash.jpg';
import userFlowImg from '../artifacts/faizur-rehman-dJpupM4LiS4-unsplash.jpg';
import Card from '../components/Card';

const Contact = () => {
    const isTinyWidth = useMediaQuery({ query: '(max-width: 435px)' });
    const isSmallWidth = useMediaQuery({ query: '(max-width: 1024px)' });

    React.useEffect(() => { document.title = 'Gripfire, Inc. - Services' }, []);

    return (
        <Box sx={rootSx}>
            <Box sx={wrapperSx}>
                <StarField />
                <Box sx={leftColumnSx}>
                    <Typography variant="h2" sx={titleSx}>
                        Gripfire Inc Provided Services
                    </Typography>

                    <Stack spacing={2}>
                        <Typography variant="h6" sx={descrSx}>
                            Depth and breadth of the field is key in both the private and public
                            sectors. Supporting existing products, re-forging or carving new
                            pathways and championing entirely new capabilities drives us.
                        </Typography>

                        <Typography variant="h6" sx={descrSx}>
                            Technology is forever evolving. The newest, latest trends are important
                            options for considering a well thoughtout durable solution.
                        </Typography>
                    </Stack>
                </Box>
                {!isTinyWidth && (
                    <Box sx={rightColumnSx}>
                        <img src={focusImgSrc} width="300px" alt="teamwork" style={imgSx} />
                    </Box>
                )}
            </Box>
            {isTinyWidth && (
                <Box sx={{
                    ...servicesSx,
                    mt: 8,
                }}>
                    <Stack direction="column" spacing={6} justifyContent="center" justifyItems="center"
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                        <Fade direction="up" triggerOnce={true}>
                            <Card
                                image={driveSrc}
                                imageAlt="Resources"
                                title="Resources"
                                descr="We work with our clients to determine their resource needs. This is a critical ingredient in the first steps." />
                        </Fade>
                        <Fade direction="up" triggerOnce={true} delay={500}>
                            <Card
                                image={goalsImg}
                                imageAlt="Goals"
                                title="Goals"
                                descr="We understand the full intent, workflow, etc. behind the goals. Effective analysis is always part of the plan." />
                        </Fade>
                        <Fade direction="up" triggerOnce={true} delay={1000}>
                            <Card
                                image={trendsImg}
                                imageAlt="Trends"
                                title="Trends"
                                descr="Keeping up with the latest trends yields the best options which bring to light creative ideas." />
                        </Fade>
                        <Fade direction="up" triggerOnce={true}>
                            <Card
                                image={dbImg}
                                imageAlt="Database"
                                title="Database"
                                descr="We have extensive experience in database deployment, internal design and efficient interactions" />
                        </Fade>
                        <Fade direction="up" triggerOnce={true} delay={500}>
                            <Card
                                image={serviceImg}
                                imageAlt="Services"
                                title="Software Services"
                                descr="Provide clean and organized access to server oriented services." />
                        </Fade>
                        <Fade direction="up" triggerOnce={true} delay={1000}>
                            <Card
                                image={userFlowImg}
                                imageAlt="UserExperience"
                                title="User Experience"
                                descr="The experience of the workflow for systems and users is considered throughout solution implementation." />
                        </Fade>
                    </Stack>
                </Box>
            )}
            {!isTinyWidth && (
                <Box sx={servicesSx}>
                    <Stack direction="row" spacing={6} justifyContent="center" justifyItems="center">
                        <Fade direction="up" triggerOnce={true}>
                            <Card
                                image={driveSrc}
                                imageAlt="Resources"
                                title="Resources"
                                descr="We work with our clients to determine their resource needs. This is a critical ingredient in the first steps." />
                        </Fade>
                        <Fade direction="up" triggerOnce={true} delay={500}>
                            <Card
                                image={goalsImg}
                                imageAlt="Goals"
                                title="Goals"
                                descr="We understand the full intent, workflow, etc. behind the goals. Effective analysis is always part of the plan." />
                        </Fade>
                        {!isSmallWidth && (
                            <Fade direction="up" triggerOnce={true} delay={1000}>
                                <Card
                                    image={trendsImg}
                                    imageAlt="Trends"
                                    title="Trends"
                                    descr="Keeping up with the latest trends yields the best options which bring to light creative ideas." />
                            </Fade>
                        )}
                    </Stack>
                    {isSmallWidth && (
                        <Stack direction="row" spacing={6} justifyContent="center" justifyItems="center" sx={{ pt: 6 }}>
                            <Fade direction="up" triggerOnce={true}>
                                <Card
                                    image={trendsImg}
                                    imageAlt="Trends"
                                    title="Trends"
                                    descr="Keeping up with the latest trends yields the best options which bring to light creative ideas." />
                            </Fade>
                            <Fade direction="up" triggerOnce={true} delay={500}>
                                <Card
                                    image={userFlowImg}
                                    imageAlt="UserExperience"
                                    title="User Experience"
                                    descr="The experience of the workflow for systems and users is considered throughout solution implementation." />
                            </Fade>
                        </Stack>
                    )}
                    <Stack direction="row" spacing={6} justifyContent="center" justifyItems="center" sx={{ pt: 6 }}>
                        <Fade direction="up" triggerOnce={true}>
                            <Card
                                image={dbImg}
                                imageAlt="Database"
                                title="Database"
                                descr="We have extensive experience in database deployment, internal design and efficient interactions" />
                        </Fade>
                        <Fade direction="up" triggerOnce={true} delay={500}>
                            <Card
                                image={serviceImg}
                                imageAlt="Services"
                                title="Software Services"
                                descr="Provide clean and organized access to server oriented services." />
                        </Fade>
                        {!isSmallWidth && (
                            <Fade direction="up" triggerOnce={true} delay={1000}>
                                <Card
                                    image={userFlowImg}
                                    imageAlt="UserExperience"
                                    title="User Experience"
                                    descr="The experience of the workflow for systems and users is considered throughout solution implementation." />
                            </Fade>
                        )}
                    </Stack>
                </Box>
            )}
        </Box>
    );
}

export default Contact;

const rootSx = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '6rem 0 4.5rem 0',
    position: 'relative',
    '&::before': {
        opacity: '.1',
        backgroundImage: 'url(' + bgSrc + ')',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
        content: '""',
    }
};

const servicesSx = {
    mt: '15rem',
    pb: '5rem'
}

const wrapperSx = {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
};

const titleSx = { pb: 2, fontFamily: 'Roboto Condensed' }

const descrSx = { fontFamily: 'Roboto Slab' };

const leftColumnSx = {
    p: 1,
    pt: 3,
    maxWidth: '460px'
}

const rightColumnSx = {
    minWidth: '548px'
}

const imgSx = { margin: '100px 0 0 80px', borderRadius: '5%' }
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import ReactGA from 'react-ga';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Services from './pages/Services';
import Careers from './pages/Careers';
import Layout from './components/Layout';
import Theme from './components/Theme';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/services',
        element: <Services />
      },
      {
        path: '/careers',
        element: <Careers />
      },
      {
        path: '/contact',
        element: <Contact />
      }
    ]
  }
])

function App() {
  ReactGA.initialize('UA-101945812-1', {
    debug: true,
    titleCase: false
  });
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <Theme>
      <RouterProvider router={router} />
    </Theme>
  );
}

export default App;

import React from 'react';
import Box from '@mui/material/Box';
import Link from '../components/Link';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripfire } from '@fortawesome/free-brands-svg-icons';
import GripfireText from './GripfireText';

export default function Logo() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 750px)' });
  //const isTinyScreen = useMediaQuery({ query: '(max-width: 450px)' });

  return (
    <Box sx={rootSx}>
      <Link to="/" sx={linkSx}>
        <FontAwesomeIcon icon={faGripfire} size="2x" color="red"/>
        {!isTabletOrMobile && <GripfireText />}
      </Link>
    </Box>
  );
}


const rootSx = {
  display: 'flex',
  flexBasis: '8rem',
  marginLeft: '1rem',
  alignItems: 'center',
}

const linkSx = {
  opacity: '1',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}


import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#fff'
        }
    },
    typography: {
        body1: {
            color: '#fff'
        },
        body2: {
            color: '#fff'
        },
        h2: {
            color: '#fff'
        },
        h5: {
            color: '#fff',
        },
        h6: {
            color: 'gray',
        }
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: '#db3131',
                    '&$error': {
                        color: '#db3131'
                    },
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: 'white',
                },
            },
        },
    }
});

const Theme = (props: any) => (<ThemeProvider theme={theme} {...props} />);

export default Theme;
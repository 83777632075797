import Box from '@mui/material/Box';
import NavLink from './NavLink';
import { useMediaQuery } from 'react-responsive';

const Navigation = () => {
    const isTinyWidth = useMediaQuery({ query: '(max-width: 400px)' });
    const navLinkSx = { borderRight: '1px solid gray', ...(isTinyWidth ? { fontSize: '12px' } : {}) };
    const navLinkNoBorderSx = { ...(isTinyWidth ? { fontSize: '12px' } : {}) };

    return (
        <Box>
            <NavLink to="/" sx={navLinkSx} style={activeStyle}>HOME</NavLink>
            <NavLink to="/services" sx={navLinkSx} style={activeStyle}>SERVICES</NavLink>
            <NavLink to="/careers" sx={navLinkSx} style={activeStyle}>CAREERS</NavLink>
            <NavLink to="/contact" sx={navLinkNoBorderSx} style={activeStyle}>CONTACT</NavLink>
        </Box>
    );
}

const activeStyle = ({ isActive }: any) => ({ color: isActive ? 'white' : undefined });

export default Navigation;

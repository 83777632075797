import wosb from './wosb.png';
import ReactGA from 'react-ga';
import { useMediaQuery } from 'react-responsive';
import FontAwesomeIcon from '../components/FontAwesomeIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {faCopyright} from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
    const currentYear = new Date().getFullYear();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 750px)' });
    const isTinyWidth = useMediaQuery({ query: '(max-width: 360px)' });
//https://clipground.com/images/wosb-logo-4.png
    return (
        <Box component="footer" sx={rootSx}>

            <Box sx={{ flexBasis: "33%" }}>
                <img src={wosb}
                    alt="WOSB"
                    style={{filter:'invert(1)'}}
                    width="200px"
                    className="wosb hover-zoom-bottom-left"
                    title="Certified Woman Owned Small Business" />
            </Box>

            <Box sx={copyrightSx}>
                {!isTinyWidth && (<>

                    <FontAwesomeIcon icon={faCopyright} sx={{pr:.5}} color="white"/>

                    {!isTabletOrMobile && (
                        <Typography sx={copyrightYearSx}>
                            Copyright {currentYear}
                        </Typography>
                    )}

                    <Typography>Gripfire, Inc.</Typography>
                </>)}
            </Box>

            <Box sx={findUsSx}>

                {!isTabletOrMobile && (
                    <Typography>Find us on &nbsp; &nbsp;</Typography>
                )}

                <ReactGA.OutboundLink
                    eventLabel="facebook-page"
                    to="https://www.facebook.com/Gripfire"
                    target="_blank"
                    title="Facebook"
                    className="find-us"
                >
                    <FontAwesomeIcon
                        icon={faFacebookSquare} 
                        size="lg"
                        color="white"
                        sx={{pr:1}}/>
                </ReactGA.OutboundLink>

                <ReactGA.OutboundLink
                    eventLabel="linked-in-page"
                    to="https://www.linkedin.com/company/gripfire-inc"
                    target="_blank"
                    title="LinkedIn"
                    className="find-us"
                >
                    <FontAwesomeIcon
                        size="lg"
                        color="white"
                        icon={faLinkedin} />
                </ReactGA.OutboundLink>

            </Box>

        </Box>
    );
}

const rootSx = {
    backgroundColor: '#19191A',
    bottom: '0',
    width: '100%',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    paddingTop: '0.1rem',
    borderTop: '0.1rem dimgray solid'
}

const copyrightSx={
    display: 'flex',
    flexBasis: '33%',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
};

const copyrightYearSx = {
    mr: 1
}

const findUsSx = {
    display: 'flex',
    flexBasis: '33%',
    marginRight: '1rem',
    alignItems: 'center',
    justifyContent: 'flex-end',
}
import GoogleReCAPTCHA, {ReCAPTCHAProps} from 'react-google-recaptcha';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export default function ReCAPTCHA({handleChange, errors} : ReCAPTCHAPropsType) {
  const fieldName = 'g-recaptcha-response';

  return (
    <FormControl>

      <GoogleReCAPTCHA
        aria-describedby="reCaptcha-helper-text"
        sitekey="6Lc0ICQTAAAAAMaE2opePwQjsYQOs1bWbzu08PCu"
        onChange={handleChange(fieldName)}
        theme="dark"
      />

      {errors[fieldName] && (
        <FormHelperText
          error={true}
          id="reCaptcha-helper-text">
          ReCaptcha required.
        </FormHelperText>
      )}

    </FormControl>
  );
}

interface ReCAPTCHAPropsType {
  handleChange: (fieldName: string) => ReCAPTCHAProps['onChange'];
  errors: {
    [key: string]: any;
  };
}
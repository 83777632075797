import React from 'react';
import './Dropzone.css';
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DropzoneComponent = ({ files, disabled, setFiles }: DropzoneProps) => {
  const maxSize = 25 /*mb*/ * 1000 /*kb*/ * 1000 /*bytes*/; // 25 MB
  const onDrop = React.useCallback(setFiles, [setFiles]);
  const accept = {
    "application/pdf": ['.pdf'],
    "application/msword": ['.doc', '.docx'],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ['.docx']
  };

  return (
    <Dropzone
      accept={accept}
      onDrop={onDrop}
      multiple={false}
      maxSize={maxSize}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject, fileRejections }) => {
        const isFileTooLarge = (
          fileRejections.length > 0 &&
          fileRejections
            .filter(rejection => rejection.file.size > maxSize)
            .length > 0
        );

        return (
          <section>

            {files.map((file: File, i: number) => (
              <div className="file" key={"file-" + i}>
                <span>
                  <FontAwesomeIcon icon={['fad', 'file-user']} />
                  {file.name}
                </span>
                <button
                  title="Remove file"
                  onClick={() => setFiles([])}>
                  <FontAwesomeIcon icon={['fad', 'trash-alt']} />
                </button>
              </div>
            ))}

            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={disabled ? "drag-n-drop disabled" : "drag-n-drop"}>
                {!isDragActive && 'Click here or drop a PDF/DOC'}
                {isDragActive && !isDragReject && "Drop it!"}
                {isDragReject && "File type not accepted, sorry!"}
                {isFileTooLarge && <p className="too-large">File too large (&gt;25MB)</p>}
              </div>
            </div>

          </section>
        );
      }}
    </Dropzone>
  );
}

export default DropzoneComponent;

interface DropzoneProps {
  files: File[];
  disabled: boolean;
  setFiles: (files: File[]) => void;
}
